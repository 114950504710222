import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Layout from "./Layout";
// import Login from "../containers/Login";
import Register from "../containers/Register";
import ContainerGeneral from "../containers/ContainerGeneral";
import ContainerPromokod from "../containers/ContainerPromokod";
import ContainerProfile from "../containers/ContainerProfile";
import ContainerAllCasino from "../containers/ContainerAllCasino";
import useAuth from "../hooks/useAuth";
import useDefaults from "../hooks/useDefaults";
import ContainerRestorePassword from "../containers/ContainerRestorePassword";
import ContainerResetPassword from "../containers/ContainerResetPassword";
import EmailVerificationContainer from "../containers/EmailVerification";
import ThankPageFb from "../pages/RegisterFacebook/ThankPageFB";
import RedirectPage from "../pages/RedirectPage";
import ContainerRegistrationFromFacebook from "../containers/ContainerRegistrationFromFacebook";
import ReactGA from "react-ga4";
import { useQueryParams } from "./QueryParams";
import RegisterPush from "../pages/RegisterPush";
import ThankRecreative from "../pages/ThankRecreative";
import RedirectOfferSelzy from "../pages/RedirectOfferSelzy";
import Unsubscribe from "../pages/Unsubscribe";
import Canada from "../canada/Canada";
import { PlinkoGamePage } from "../plinko/Stuff/index";
import Aviator from "../aviator";
import SuperGra from "../supergra/SuperGra";

const AppRoutes = () => {
  const { checkAuth } = useAuth();
  const { getDefaults } = useDefaults();
  let { pid, sub1, sub2, clickid, ref_id } = useQueryParams();
  const { defaults } = useDefaults();
  useEffect(() => {
    checkAuth().then(() => {});
    getDefaults().then(() => {});
  }, []);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  useEffect(() => {
    let dataQuery = searchParams.get("recreative");
    let pixelId = searchParams.get("pixel");
    let subId1 = searchParams.get("sub1");
    let subId2 = searchParams.get("sub2");
    let subId3 = searchParams.get("sub3");
    let subId4 = searchParams.get("sub4");
    let subId5 = searchParams.get("sub5");
    let subId6 = searchParams.get("sub6");
    let subId7 = searchParams.get("sub7");
    let subId8 = searchParams.get("sub8");
    let offerId = searchParams.get("offer_id");
    let pid = searchParams.get("pid");
    // let key = searchParams.get("key");
    if (dataQuery === "true") {
      sessionStorage.setItem("recreative", "true");
    }
    if (pixelId !== null) window.localStorage.setItem("pixel", pixelId);
    if (subId1 !== null) window.localStorage.setItem("sub1", subId1);
    if (subId2 !== null) window.localStorage.setItem("sub2", subId2);
    if (subId3 !== null) window.localStorage.setItem("sub3", subId3);
    if (subId4 !== null) window.localStorage.setItem("sub4", subId4);
    if (subId5 !== null) window.localStorage.setItem("sub5", subId5);
    if (subId6 !== null) window.localStorage.setItem("sub6", subId6);
    if (subId7 !== null) window.localStorage.setItem("sub7", subId7);
    if (subId8 !== null) window.localStorage.setItem("sub8", subId8);
    if (offerId !== null) window.localStorage.setItem("offer_id", offerId);
    if (pid === null) {
      pid = "17";
      window.localStorage.setItem("pid", pid);
    } else {
      window.localStorage.setItem("pid", pid);
    }
    // if (key !== null) window.localStorage.setItem("key", key);
  }, []);
  // useEffect(() => {
  //   const lsPid = window.localStorage.getItem("pid");
  //   const lsSub1 = window.localStorage.getItem("sub1");
  //   const lsSub2 = window.localStorage.getItem("sub2");
  //   const lsSub3 = window.localStorage.getItem("clickid");
  //   const refId = window.localStorage.getItem("ref_id");
  //   if (defaults.pid) {
  //     window.localStorage.setItem(
  //       "pid",
  //       Boolean(pid) ? pid : Boolean(lsPid) ? lsPid : defaults.pid
  //     );
  //     window.localStorage.setItem(
  //       "sub1",
  //       Boolean(sub1) ? sub1 : Boolean(lsSub1) ? lsSub1 : defaults.sub1
  //     );
  //     window.localStorage.setItem(
  //       "sub2",
  //       Boolean(sub2) ? sub2 : Boolean(lsSub2) ? lsSub2 : "direct"
  //     );
  //     if (clickid) {
  //       window.localStorage.setItem(
  //         "clickid",
  //         Boolean(clickid) ? clickid : Boolean(lsSub3) ?? lsSub3
  //       );
  //     }
  //     if (ref_id) {
  //       window.localStorage.setItem(
  //         "ref_id",
  //         Boolean(ref_id) ? ref_id : Boolean(refId) ?? refId
  //       );
  //     }
  //   }
  //   if (lsPid === "22" && defaults.pid) {
  //     window.localStorage.removeItem("pid");
  //     window.localStorage.setItem("pid", defaults.pid);
  //   }
  // }, [defaults, pid, sub1]);

  const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
      ReactGA.initialize("G-HVX24ZG10E");
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
  };
  usePageViews();

  return (
    <Switch>
      <Route path="/supergra" exact>
        <SuperGra />
      </Route>
      <Route path="/canada" exact>
        <Canada />
      </Route>
      <Route path="/plinko" exact>
        <PlinkoGamePage />
      </Route>
      <Route path="/aviator" exact>
        <Aviator />
      </Route>
      <Route path="/fbregister" exact>
        <ContainerRegistrationFromFacebook />
      </Route>
      <Route path="/thankRe" exact>
        <ThankRecreative />
      </Route>
      <Route path="/thank" exact>
        <ThankPageFb />
      </Route>
      <Layout>
        <Route path="/" exact>
          <ContainerGeneral />
        </Route>
        <Route path="/redirect" exact>
          <RedirectPage />
        </Route>
        <Route path="/redirs" exact>
          <RedirectOfferSelzy />
        </Route>
        <Route path="/promokod" exact>
          <ContainerPromokod />
        </Route>
        <Route path="/allcasino" exact>
          <ContainerAllCasino />
        </Route>
        <Route path="/login" exact>
          <Register />
        </Route>
        <Route path="/register" exact>
          <Register />
        </Route>
        {/* <Route path="/registertest" exact>
          <RegisterPush />
        </Route> */}
        <Route path="/profile" exact>
          <ContainerProfile />
        </Route>
        <Route path="/reset-password" exact>
          <ContainerResetPassword />
        </Route>
        <Route path="/restore-password" exact>
          <ContainerRestorePassword />
        </Route>
        <Route path="/verify" exact>
          <EmailVerificationContainer />
        </Route>
        <Route path="/unsubscribe" exact>
          <Unsubscribe />
        </Route>
      </Layout>
    </Switch>
  );
};

export default AppRoutes;
